import React from 'react'
import {graphql} from 'gatsby'
import get from 'lodash/get'
import {Helmet} from 'react-helmet'
import Hero from '../components/hero'
import LayoutLanding from '../components/layout-landing'
// import ArticlePreview from '../components/article-preview'

class RootIndex extends React.Component {
  render() {
    // const articles = get(this, 'props.data.allContentfulArticle.edges');
    const mainPage = get(this, 'props.data.allContentfulMainPage.edges')[0].node;
    const logoPath = get(this, 'props.data.file.publicURL');
    const constructionImg = get(this, 'props.data.construction.publicURL');
    const ogImagePath = 'https://amguitarra.org' + logoPath;

    return (
      <LayoutLanding location={this.props.location}>
        <div>
          <Helmet title={mainPage.title}>
            <meta property="og:title" content="Sitio Oficial de la Asociación Mexicana de Guitarra"/>
            <meta property="og:description"
                  content="Visita el Sitio oficial de la Asociación Mexicana de Guitarra Clásica y Popular para encontrar información acerca de conciertos, clases magistrales, diplomados, y otros eventos relacionados con la guitarra en México."/>
            <meta property="og:type" content="website"/>
            <meta property="og:url" content="https://www.amguitarra.org"/>
            <meta property="og:image" content={ogImagePath}/>
            <meta property="og:image:width" content="500"/>
            <meta property="og:image:height" content="188"/>
            <link rel="sortcut icon" href="/favicon.png" type="image/x-icon"/>
          </Helmet>
          <Hero/>
          <section className="construction">
            <div className="construction-sections">
              <div>
                <img src={constructionImg} alt="Volvemos pronto"/>
              </div>
              <div>
                <h2>Estamos afinando cada detalle</h2>
                <p>Nuestra página web dedicada a la Asociación Mexicana de Guitarra Clásica y Popular está en proceso de
                  renovación. Les invitamos a visitarnos
                  nuevamente en algunos días para acceder a todo el contenido actualizado.</p>

                <p>Mientras tanto, pueden seguirnos en nuestras redes sociales o contactarnos a través de <a
                  href="mailto:contacto@amguitarra.org">contacto@amguitarra.org</a>.</p>

                <p>Agradecemos su paciencia y comprensión.</p>
              </div>
            </div>
          </section>
          <div className="container container-white">
            <div className="main-desc"
                 dangerouslySetInnerHTML={{
                   __html: mainPage.description.childMarkdownRemark.html
                 }}
            />
            {/*<h2 className="section-headline">Articulos recientes</h2>*/}
            {/*<ul className="article-list">*/}
            {/*  {articles?.map(({node}) => {*/}
            {/*    return (*/}
            {/*      <li key={node.slug}>*/}
            {/*        <ArticlePreview article={node}/>*/}
            {/*      </li>*/}
            {/*    )*/}
            {/*  })}*/}
            {/*</ul>*/}
          </div>
        </div>
      </LayoutLanding>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
    query HomeQuery {
        allContentfulArticle(limit: 6, sort: { fields: [publishDate], order: DESC }) {
            edges {
                node {
                    title
                    slug
                    publishDate(formatString: "MMMM Do, YYYY")
                    tags
                    heroImage {
                        fluid(maxWidth: 350, maxHeight: 250, resizingBehavior: SCALE) {
                            ...GatsbyContentfulFluid_tracedSVG
                        }
                    }
                    description {
                        childMarkdownRemark {
                            html
                        }
                    }
                }
            }
        }
        allContentfulMainPage {
            edges {
                node {
                    title
                    description {
                        childMarkdownRemark {
                            html
                        }
                    }
                }
            }
        }
        file(relativePath: {eq: "imagotipo-small.jpg"}) {
            publicURL
        }
        construction: file(relativePath: {eq: "construccion.jpeg"}) {
            publicURL
        }
    }
`
