import React from 'react'
import Img from 'gatsby-image'
import {graphql, useStaticQuery} from 'gatsby';
// import LandingVideo from '../assets/videos/landing.webm';

export default () => {
  const logo = useStaticQuery(graphql`
    {
      file(relativePath: {eq: "imagotipo.jpg"}) {
        childImageSharp {
          fluid(maxHeight: 300) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `);

  // const [zoomClass, setZoomClass] = React.useState('');

  // React.useEffect(() => {
  //   setTimeout(() => {
  //     setZoomClass('zoom-effect');
  //     const vid = document.getElementById('landing-video');
  //     vid.playbackRate = 0.5;
  //   }, 5);
  // }, []);

  return (
    <>
      {/*<div className='landing-video-box'>*/}
      {/*  <video id='landing-video' className={'landing-video ' + zoomClass} autoPlay loop preload='auto' muted>*/}
      {/*    <source src={LandingVideo} type='video/mp4'/>*/}
      {/*  </video>*/}
      {/*  <section className='landing-message-box'>*/}
      {/*    <div className='jumbotron'>*/}
      {/*      <div className='container'>*/}
      {/*        <h1 className='landing-title'>Asociacion <span className='mexicana'>Mexicana</span> de Guitarra</h1>*/}
      {/*        <sub className='landing-subtitle'>clásica y popular</sub>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </section>*/}
      {/*</div>*/}
      <div className='hero'>
        <Img className="hero-logo" fluid={logo.file.childImageSharp.fluid}
             alt="Asociación Mexicana de la guitarra"/>
      </div>
    </>
  )
}
